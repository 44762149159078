import React, {useEffect} from 'react'
import styles from './styles.css'
import Navbar from './Navbar'
import WhatsappChat from './WhatsappChat'
import Footer from './Footer'
import Facebook from './Facebook'
import {useLocation} from 'react-router-dom'

export default function Layout(props) {
  const location = useLocation()
  // scroll to top on route change
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <div className={styles.container}>
      <Navbar />
      {props.children}
      <div className={styles.WhatsappChat}>
        <WhatsappChat />
      </div>
      <Footer />
      <React.Suspense fallback={null}>
        <Facebook />
      </React.Suspense>
    </div>
  )
}
