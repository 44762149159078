import React from 'react'
import styles from './styles.css'
import Section from 'App/components/Section'
import withMessage from 'orionsoft-parts/lib/decorators/withMessage'
import PropTypes from 'prop-types'
import withGraphQL from 'react-apollo-decorators/lib/withGraphQL'
import gql from 'graphql-tag'
import ReferProductCard from './ProductsCards'

const fragment = gql`
  fragment setUserPaymentDataFragment on User {
    _id
    paymentData {
      bankId
      bankAccountType
      bankAccountNumber
    }
  }
`

@withGraphQL(gql`
  query getMyPaymentData {
    user: me {
      ...setUserPaymentDataFragment
    }
  }
  ${fragment}
`)
@withMessage
export default class Refers extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    showMessage: PropTypes.func
  }

  state = {}

  // const [dropdown, setDropdown] = useState(false)

  render() {
    if (!this.props.user) return

    return (
      <div className={styles.container}>
        <Section
          top
          description="Aquí puedes elegir el producto que tu referido quiere contratar, para luego llenar el formulario con sus datos.">
          <br />
          <br />
        </Section>
        <div className={styles.contentContainer}>
          <div className={styles.titleDiv}>
            <h1 className={styles.formTitle}>Recomendaciones de un buen referido</h1>
            <img className={styles.dropdown} src="/newImages/desplegar.png" />
          </div>
          <div className={styles.contentWrap}>
            <div className={styles.recomendationList}>
              <span className={styles.recomendationItem}>
                <img className={styles.check} src="/newImages/check.png" />
                Mayor de 26 años.
              </span>
              <span className={styles.recomendationItem}>
                <img className={styles.check} src="/newImages/check.png" />
                Con un trabajo estable.
              </span>
              <span className={styles.recomendationItem}>
                <img className={styles.check} src="/newImages/check.png" />
                Renta superior a $1.000.000.
              </span>
              <span className={styles.recomendationItem}>
                <img className={styles.check} src="/newImages/check.png" />
                *El producto Referidos Auto y Referidos APV no necesita ninguno de los requisitos
                anteriores.
              </span>
              <span className={styles.recomendationItem}>
                <img className={styles.check} src="/newImages/check.png" />
                *Importante: Ingresa datos de personas que conozcas.
              </span>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
        <div className={styles.cards}>
          <ReferProductCard
            img="/newImages/referidos_auto.png"
            description="Refiere a un conocido interesado en contratar un Seguro de Auto, puedes ser tú mismo y a la vez ganas lucas."
            description2="Si tu referido contrata un seguro de auto  ¡Ganas $25.000 bruto!"
            foot="Si tu referido contrata un seguro de auto  ¡Ganas $25.000 bruto!"
            url="/referidos-auto/empezar"
            url2="/referidos-auto"
          />
          <ReferProductCard
            img="/newImages/referidos_apv.png"
            description="Refiere un conocido interesado en guardar el 10% de su AFP en un APV, puedes ser tú mismo y a la vez ganas lucas."
            description2="Si tu referido contrata el producto  ¡Ganas $50.000 bruto!"
            foot="Si tu referido contrata el producto  ¡Ganas $50.000 bruto!"
            url="/referidos-apv10/empezar"
            url2="/referidos-apv10"
          />
          <ReferProductCard
            img="/newImages/referidos_vida.png"
            description="Refiere un conocido interesado en contratar un Seguro de Vida, Seguro con Ahorro o APV."
            description2="Si tu referido contrata el producto  ¡Ganas $50.000 bruto!"
            foot="Si tu referido contrata el producto  ¡Ganas $50.000 bruto!"
            url="/referidos/empezar"
            url2="/referidos/vida"
          />
          <ReferProductCard
            img="/newImages/referidos_hipotecario.png"
            description="Refiere un conocido interesado en refinanciar o solicitar un Crédito Hipotecario."
            description2="Si tu referido contrata el producto  ¡Ganas entre $50.000 a $150.000 bruto!"
            foot="Si tu referido contrata el producto  ¡Ganas entre $50.000 a $150.000 bruto!"
            url="/referidos-hipotecario/empezar"
            url2="/referidos-hipotecario"
          />
        </div>
      </div>
    )
  }
}
