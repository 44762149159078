import React, {Fragment} from 'react'
import styles from './styles.css'
import PropTypes from 'prop-types'
import range from 'lodash/range'
import autobind from 'autobind-decorator'

export default class SixDigitInput extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    autoFocus: PropTypes.bool,
    onReady: PropTypes.func,
    digits: PropTypes.number,
    errorMessage: PropTypes.node
  }

  static defaultProps = {
    autoFocus: true,
    digits: 6,
    onReady: () => {}
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      this.focus()
    }
  }

  @autobind
  focus() {
    if (this.props.value) {
      this.props.onChange('')
    }
    this.refs[`input_0`].focus()
  }

  getNextIndex(value) {
    if (!value) return 0
    if (value.length + 1 > this.props.digits) return 0
    return value.length
  }

  @autobind
  focusNext() {
    const value = this.props.value
    const index = this.getNextIndex(value)
    this.refs[`input_${index}`].focus()
  }

  isReady() {
    return (this.props.value || '').length === this.props.digits
  }

  onChange(event, index) {
    let value = event.target.value || ''
    let oldValue = this.props.value || ''
    let newValue = oldValue + value
    newValue = newValue.replace(/[^0-9A-Za-z]/g, '')

    if (newValue.length > this.props.digits) {
      const last = newValue[newValue.length - 1]
      this.props.onChange(last)
    } else {
      this.props.onChange(newValue)
    }
    setTimeout(() => {
      if (this.isReady()) {
        this.refs[`input_${index}`].blur()
        setTimeout(() => this.props.onReady(newValue), 200)
      } else {
        this.focusNext()
      }
    }, 1)
  }

  onKeyDown(event, index) {
    if (event.key === 'Backspace') {
      const newVal = (this.props.value || '1').slice(0, -1)
      this.props.onChange(newVal)
      setTimeout(this.focusNext, 1)
    }
  }

  renderInputs() {
    return range(this.props.digits).map(index => {
      const value = (this.props.value || '')[index] || ''
      return (
        <Fragment key={index}>
          <div className={styles.inputContainer}>
            <input
              value={value.toUpperCase()}
              ref={`input_${index}`}
              className={styles.input}
              placeholder="•"
              onChange={event => this.onChange(event, index)}
              onKeyDown={event => this.onKeyDown(event, index)}
            />
          </div>
          {index === this.props.digits / 2 - 1 ? <div className={styles.divider}>-</div> : null}
        </Fragment>
      )
    })
  }

  render() {
    const className = this.isReady() ? styles.ready : styles.container
    return (
      <div>
        <div className={className} onClick={this.focus}>
          {this.renderInputs()}
        </div>
        <div className="os-input-error">{this.props.errorMessage}</div>
      </div>
    )
  }
}
