import React from 'react'
import styles from './styles.css'
import PropTypes from 'prop-types'

export default class DeliveryType extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array
  }

  componentDidMount() {
    this.checkValue()
  }

  componentDidUpdate(prevProps, prevState) {
    this.checkValue()
  }

  checkValue() {
    if (!this.props.options) return
    if (!this.props.value) return
    const selected = this.props.options.find(option => option.value === this.props.value)
    if (!selected) {
      this.props.onChange(null)
    }
  }

  renderOption({label, value, Icon} = {}) {
    const selected = this.props.value === value
    return (
      <div
        key={value}
        className={selected ? styles.selected : styles.option}
        onClick={() => this.props.onChange(value)}>
        <div className={styles.icon}>
          <Icon size={22} />
        </div>
        <div className={styles.label}>{label}</div>
      </div>
    )
  }

  render() {
    return (
      <div className={styles.container}>
        {this.props.options.map(option => this.renderOption(option))}
      </div>
    )
  }
}
