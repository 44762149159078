import React from 'react'
import styles from './styles.css'
import Container from 'orionsoft-parts/lib/components/Container'
import getEnv from 'App/Root/getEnv'
// import * as Sentry from '@sentry/browser'

export default class ErrorHandler extends React.Component {
  state = {}

  static getDerivedStateFromError(error) {
    // if (!error.isApolloError) throw error
    return {error}
  }

  componentDidCatch(error, info) {
    // if (!error.isApolloError) throw error
    console.log('Component did catch', error)
    if (getEnv() === 'prod') {
      // Sentry.captureException(error)
      setTimeout(() => window.location.reload(true), 10000)
    }
  }

  renderError() {
    if (this.state.error.message.includes('Loading chunk')) {
      return (
        <div className={styles.container}>
          <div className={styles.title}>
            <Container>Actualizando versión del sitio</Container>
          </div>
          <div className={styles.message}>
            <Container>
              <div>Estamos actualizando la versión de este sitio web</div>
            </Container>
            <br />
            <Container>Cargando...</Container>
          </div>
        </div>
      )
    }
    return (
      <div className={styles.container}>
        <div className={styles.title}>
          <Container>Ocurrió un error</Container>
        </div>
        <div className={styles.message}>
          <Container>
            <div>{this.state.error.message}</div>
            <pre>{this.state.error.stack}</pre>
          </Container>
          <br />
          <Container>Intentando nuevamente...</Container>
        </div>
      </div>
    )
  }

  render() {
    if (this.state.error) return this.renderError()
    return this.props.children
  }
}
