import React from 'react'
import {Route, Switch} from 'react-router-dom'
import Profile from './Profile'
import Tabs from 'orionsoft-parts/lib/components/Tabs'
import styles from './styles.css'
import Container from 'orionsoft-parts/lib/components/Container'
import Security from './Security'
import useForceLogin from 'App/hooks/useForceLogin'
import Cards from './Cards'
import Payment from './Payment'
import Executive from './Executive'
import BannerRefer from './Banner'
import Refers from './Refers'
import useQuery from 'apollo-hooks/lib/useApolloQuery'
import gql from 'graphql-tag'

export default function Layout() {
  useForceLogin()

  const {me} = useQuery({
    query: gql`
      query getMeData {
        me {
          _id
          profile {
            firstName
            lastName
          }
        }
      }
    `
  })

  if (!me) return null

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.titleDiv}>
          <h1 className={styles.title}>¡Bienvenido {me.profile.firstName}!</h1>
        </div>
        <Tabs
          items={[
            {title: 'Mi perfil', path: '/perfil'},
            {title: 'Mis referidos', path: '/perfil/negocios'},
            {title: 'Mis ganancias', path: '/perfil/pagos'},
            {title: 'Referir', path: '/perfil/referidos'}
          ]}
        />
      </div>
      <Container>
        <Switch>
          <Route path="/perfil/negocios" component={Cards} />
          <Route path="/perfil/pagos" component={Payment} />
          <Route path="/perfil/security" component={Security} />
          <Route path="/perfil/ejecutivo" component={Executive} />
          <Route path="/perfil/referidos" component={Refers} />
          <Route path="/perfil" component={Profile} />
        </Switch>
      </Container>
      <BannerRefer />
    </div>
  )
}
