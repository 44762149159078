import React from 'react'
import styles from './styles.css'
import Container from 'orionsoft-parts/lib/components/Container'
import {Link} from 'react-router-dom'
import Button from 'orionsoft-parts/lib/components/Button'
import useUserId from 'App/hooks/useUserId'
import useRouter from 'App/hooks/useRouter'
import logout from 'App/helpers/auth/logout'
import useRoles from 'App/hooks/useRoles'
import Executive from './Executive'
import useEffect from 'App/hooks/useEffect'

export default function Navbar(props) {
  const userId = useUserId()
  const {history, location} = useRouter()
  const roles = useRoles()
  const showConsorcio = location.pathname.startsWith('/referidos-hipotecario-destacame')

  useEffect(() => {
    document.title = showConsorcio ? 'Consorcio' : 'Mejor con Lucas'
  }, [showConsorcio])

  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.navbarInner}>
          {showConsorcio ? (
            <Link to="/" className={styles.logoConsorcio}>
              <img src="/images/consorcio.png" />
            </Link>
          ) : (
            <Link to="/" className={styles.logoBoth}>
              <img className={styles.logoBothMCL} src="/images/logo.png" />
              <img className={styles.logoBothConsorcio} src="/images/consorcio.png" />
            </Link>
          )}

          <div className={styles.spacer} />
          <div className={styles.user}>
            {userId ? (
              history.location.pathname.startsWith('/perfil') ? (
                <Button primary onClick={() => logout()}>
                  Cerrar sesión
                </Button>
              ) : (
                <Button primary to="/perfil">
                  Mi Perfil
                </Button>
              )
            ) : (
              <>
                <Button primary to="/perfil">
                  Iniciar sesión
                </Button>
              </>
            )}
            {roles && roles.includes('admin') ? (
              <Button danger to="/admin" style={{marginLeft: 10}}>
                Admin
              </Button>
            ) : null}
            <React.Suspense fallback={null}>{userId ? <Executive /> : null}</React.Suspense>
          </div>
        </div>
      </Container>
    </div>
  )
}
