import React from 'react'
import styles from './styles.css'
import Container from 'orionsoft-parts/lib/components/Container'
import Button from 'orionsoft-parts/lib/components/Button'
import BannerRefer from '../../Banner'

export default function Welcome(props) {
  return (
    <div className={styles.container}>
      <Container size="medium">
        <div className={styles.content}>
          <div className={styles.title}>
            <img
              src="/newImages/referidos_hipotecario.png"
              alt="Programa de referidos hipotecarios"
            />
            <h1>
              Con el <b>Crédito Hipotecario de Consorcio</b> obtén una completa asesoría de
              ejecutivos especialistas para que{' '}
              <b>tu sueño de la casa propia o el de tu referido se haga realidad</b>
            </h1>
          </div>
          <div className={styles.info}>
            <div className={styles.infoLine}>
              <div className={styles.subtitle}>
                Características del Crédito Hipotecario online en Consorcio
              </div>
            </div>
            <div className={styles.infoLine}>
              <div className={styles.infoIcon}>
                <img src="/newImages/check.png" />
              </div>
              <div className={styles.infoText}>
                Financia la compra de tu vivienda nueva o usada o refinancia la deuda que tienes con
                otra institución.
              </div>
            </div>
            <div className={styles.infoLine}>
              <div className={styles.infoIcon}>
                <img src="/newImages/check.png" />
              </div>
              <div className={styles.infoText}>Cubre hasta el 80% del valor de la propiedad.</div>
            </div>
            <div className={styles.infoLine}>
              <div className={styles.infoIcon}>
                <img src="/newImages/check.png" />
              </div>
              <div className={styles.infoText}>
                Tú eliges el plazo en el quieres pagar tu crédito, entre 8 y 30 años.
              </div>
            </div>
            <div className={styles.infoLine}>
              <div className={styles.infoIcon}>
                <img src="/newImages/check.png" />
              </div>
              <div className={styles.infoText}>
                Elige nuestro producto autoajustable, en donde cada mes tú eliges cuánto pagar de
                dividendo.
              </div>
            </div>
            <div className={styles.infoLine}>
              <div className={styles.infoIcon}>
                <img src="/newImages/check.png" />
              </div>
              <div className={styles.infoText}>
                Aprovecha nuestros convenientes precios de seguros, haciendo que el valor de tu
                dividendo sea más bajo.
              </div>
            </div>
          </div>
          <div className={styles.iconoHipotecario}>
            <img src="/newImages/icono_hipotecario.png" />
          </div>
          <div className={styles.info}>
            <div className={styles.infoLine}>
              <div className={styles.infoIcon}>
                <img src="/newImages/check.png" />
              </div>
              <div className={styles.infoText2}>
                Refiere un interesado en <b>obtener o refinanciar un Crédito Hipotecario</b> desde
                tu perfil, puedes ser tú mismo y a la vez ganas lucas.
              </div>
            </div>
            <div className={styles.infoLine}>
              <div className={styles.infoIcon}>
                <img src="/newImages/check.png" />
              </div>
              <div className={styles.infoText2}>
                Si tu referido{' '}
                <b>
                  contrata o refinancia un crédito hipotecario ganas entre $50.000 a $150.000 bruto.
                </b>
              </div>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <Button primary to="/referidos-hipotecario/empezar">
              Empezar
            </Button>
          </div>
        </div>
      </Container>
      <BannerRefer />
    </div>
  )
}
