import React from 'react'
import styles from './styles.css'
import {Route, Switch, Redirect} from 'react-router-dom'
import useForceLogin from 'App/hooks/useForceLogin'
import Success from './Success'
import Faq from 'App/Pages/App/Referrals/Faq'
import Welcome from './Welcome'

export default function Referrals(props) {
  useForceLogin()

  return (
    <div className={styles.container}>
      <Switch>
        <Route path="/referidos-auto/exito" component={Success} />
        <Route
          path="/referidos-auto/referir"
          component={() => <Redirect to="/referir/referir" />}
        />
        <Route
          path="/referidos-auto/empezar"
          component={() => <Redirect to="/referir/empezar" />}
        />
        <Route path="/referidos-auto/faq" component={Faq} />
        <Route path="/referidos-auto" component={Welcome} />
      </Switch>
    </div>
  )
}
