import React from 'react'
import styles from './styles.css'

export default function Card(props) {
  const {card} = props
  return (
    <div className={styles.container}>
      <div className={styles.title}>{card.title}</div>
      <div className={styles.comments}>{card.comments}</div>
      <div className={styles.status}>{card.step.cardName}</div>
    </div>
  )
}
