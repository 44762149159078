import React from 'react'
import styles from './styles.css'
import Container from 'orionsoft-parts/lib/components/Container'
import {Link} from 'react-router-dom'
import Button from 'orionsoft-parts/lib/components/Button'

export default function Start(props) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>¡Crea una cuenta y comienza a ganar lucas!</h1>
        <Button primary to="/perfil/referidos">
          ¡Quiero ganar lucas!
        </Button>
      </div>
      <Container>
        <div className={styles.content2}>
          <div>
            <h1>¿Cómo gano lucas?</h1>
            <h3>¡Es muy simple!</h3>
            <p>
              ¿Tienes un <b>familiar, amigo, conocido o vecino</b> que quiere{' '}
              <b>contratar un Seguro de Vida, obtener un Crédito Hipotecario o asegurar su auto?</b>{' '}
              un Seguro de Vida, obtener un Crédito Hipotecario o asegurar su auto?
            </p>
            <Button to="/perfil/referidos" primary>
              Inscríbete aquí y ¡Gana lucas!
            </Button>
          </div>
          <div className={styles.map}>
            <div className={styles.aa}>
              <div className={styles.box}>
                <span className={styles.num}>1</span>
                <img src="/newImages/home_1img.png" />
                <p>INSCRÍBETE</p>
              </div>
              <div className={styles.box}>
                <span className={styles.num}>2</span>
                <img src="/newImages/home_2img.png" />
                <p>REFIERE</p>
              </div>
            </div>
            <div className={styles.aa}>
              <div className={styles.box}>
                <span className={styles.num}>3</span>
                <img src="/newImages/home_3img.png" />
                <p>TU REFERIDO CONTRATÓ EL PRODUCTO</p>
              </div>
              <div className={styles.box}>
                <span className={styles.num}>4</span>
                <img src="/newImages/lucas_paso4.png" />
                <p>¡GANA LUCAS!</p>
              </div>
            </div>
          </div>
          <div className={styles.infoContainer}>
            <div className={styles.info}>
              <img src="/newImages/check.png" />
              <p>Refiere a tus conocidos en tu perfil creado.</p>
            </div>
            <div className={styles.info}>
              <img src="/newImages/check.png" />
              <p>
                Ganas $50.000 bruto si tu referido contrata uno de nuestros productos, Referidos
                Vida o Referidos APV.
              </p>
            </div>
            <div className={styles.info}>
              <img src="/newImages/check.png" />
              <p>
                Ganas $25.000 bruto si uno de tus referidos contrata nuestro producto Referidos
                Auto.
              </p>
            </div>
            <div className={styles.info}>
              <img src="/newImages/check.png" />
              <p>
                Ganas entre $50.000 y $150.000 bruto si uno de tus referidos refinancia o contrata
                un Crédito Hipotecario de Consorcio.
              </p>
            </div>
          </div>
        </div>
        <div className="row center-xs">
          <div className="col-xs-12 col-sm-6">
            <Link className={styles.button} to="/referidos-auto">
              <div className={styles.imageContainer}>
                <img src="/newImages/referidos_auto.png" alt="Referidos auto" />
              </div>
              <Button onClick={() => {}} danger>
                Referir
              </Button>
            </Link>
          </div>
          <div className="col-xs-12 col-sm-6">
            <Link className={styles.button} to="/referidos-apv10">
              <div className={styles.imageContainer}>
                <img src="/newImages/referidos_apv.png" alt="Referidos" />
              </div>
              <Button onClick={() => {}} danger>
                Referir
              </Button>
            </Link>
          </div>
          <div className="col-xs-12 col-sm-6">
            <Link className={styles.button} to="/referidos/vida">
              <div className={styles.imageContainer}>
                <img src="/newImages/referidos_vida.png" alt="Referidos" />
              </div>
              <Button onClick={() => {}} danger>
                Referir
              </Button>
            </Link>
          </div>
          <div className="col-xs-12 col-sm-6">
            <Link className={styles.button} to="/referidos-hipotecario">
              <div className={styles.imageContainer}>
                <img src="/newImages/referidos_hipotecario.png" alt="Referidos" />
              </div>
              <Button onClick={() => {}} danger>
                Referir
              </Button>
            </Link>
          </div>
        </div>
      </Container>
    </div>
  )
}
