import React from 'react'
import styles from './styles.css'
import AutoForm from 'App/components/AutoForm'
import useQuery from 'apollo-hooks/lib/useApolloQuery'
import gql from 'graphql-tag'

export default function CardForm(props) {
  const {campaignId} = props
  const {campaign} = useQuery({
    query: gql`
      query getCampaign($campaignId: ID) {
        campaign(campaignId: $campaignId) {
          _id
          name
          params
          internalParams
        }
      }
    `,
    variables: {campaignId}
  })

  const schema = {
    campaignId: {
      type: 'ID',
      optional: true
    },
    cardId: {
      type: 'ID',
      optional: true
    }
  }

  if (props.internal) {
    schema.internalData = {
      type: campaign.internalParams,
      optional: true
    }
  } else {
    schema.data = {
      type: campaign.params,
      optional: true
    }
  }

  return (
    <div className={styles.container}>
      <AutoForm schema={schema} only="data" {...props} doc={props.doc} />
    </div>
  )
}
