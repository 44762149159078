import React from 'react'
import styles from './styles.css'
import {Route, Switch, Redirect} from 'react-router-dom'
import useForceLogin from 'App/hooks/useForceLogin'
import Info1 from './Info1'
import Info2 from './Info2'
import Info3 from './Info3'
import Success from './Success'
import Welcome from './Welcome'
import Faq from 'App/Pages/App/Referrals/Faq'

export default function Referrals(props) {
  useForceLogin()
  return (
    <div className={styles.container}>
      <Switch>
        <Route path="/referidos-hipotecario/info-1" component={Info1} />
        <Route path="/referidos-hipotecario/info-2" component={Info2} />
        <Route path="/referidos-hipotecario/info-3" component={Info3} />
        <Route
          path="/referidos-hipotecario/referir"
          component={() => <Redirect to="/referir/referir" />}
        />
        <Route
          path="/referidos-hipotecario/empezar"
          component={() => <Redirect to="/referir/empezar" />}
        />
        <Route path="/referidos-hipotecario/exito" component={Success} />
        <Route path="/referidos-hipotecario/faq" component={Faq} />
        <Route path="/referidos-hipotecario" component={Welcome} />
      </Switch>
    </div>
  )
}
