import React from 'react'
import styles from './styles.css'
import PropTypes from 'prop-types'
import Creatable from 'react-select/lib/Creatable'
import moment from 'moment'
import autobind from 'autobind-decorator'

const format = 'DD-MM-YYYY'

export default class DateArray extends React.Component {
  static propTypes = {
    value: PropTypes.array,
    onChange: PropTypes.func,
    label: PropTypes.node,
    description: PropTypes.node,
    errorMessage: PropTypes.node
  }

  getValue() {
    return (this.props.value || []).map(date => {
      return {
        label: moment(date).format('LL'),
        value: moment(date).format(format)
      }
    })
  }

  @autobind
  onChange(dates, actionMeta) {
    const value = dates.map(option => {
      return moment(option.value, format).toDate()
    })
    this.props.onChange(value)
  }

  formatCreateLabel(value) {
    return 'Agregar ' + moment(value, format).format('LL')
  }

  isValid(value) {
    return moment(value, format).isValid()
  }

  render() {
    return (
      <div className={styles.container}>
        <div className="label">{this.props.label}</div>
        <Creatable
          isMulti
          classNamePrefix="orion-select"
          placeholder="Escribe una fecha"
          value={this.getValue()}
          noOptionsMessage={() => 'Escribe una fecha (dd-mm-yyyy)'}
          formatCreateLabel={this.formatCreateLabel}
          isValidNewOption={this.isValid}
          onChange={this.onChange}
        />
        <div className="description">{this.props.description}</div>
        <div className="os-input-error">{this.props.errorMessage}</div>
      </div>
    )
  }
}
