import React from 'react'
import styles from './styles.css'
import {Route, Switch, Redirect} from 'react-router-dom'
import Start from './Start'
import Submit from './Submit'
import Success from './Success'
import useUserId from 'App/hooks/useUserId'
import Login from 'App/Pages/App/HouseReferralsDestacame/Login'

export default function Referrals(props) {
  const userId = useUserId()
  if (!userId) return <Login />
  return (
    <div className={styles.container}>
      <Switch>
        <Route path="/referidos-hipotecario-uhomie/referir" component={Submit} />
        <Route path="/referidos-hipotecario-uhomie/exito" component={Success} />
        <Route path="/referidos-hipotecario-uhomie/empezar" component={Start} />
        <Redirect path="/referidos-hipotecario-uhomie" to="/referidos-hipotecario-uhomie/empezar" />
      </Switch>
    </div>
  )
}
