import React from 'react'
import styles from './styles.css'
import Container from 'orionsoft-parts/lib/components/Container'
import CodeLogin from 'App/Pages/Auth/CodeLogin'
import useUserId from 'App/hooks/useUserId'
import useMessage from 'App/hooks/useMessage'
import Button from 'orionsoft-parts/lib/components/Button'

export default function Banner(props) {
  const userId = useUserId()
  const showMessage = useMessage()
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>
          ¡Estás a un paso de ganar lucas!
        </h1>
        <Button primary to="/perfil" target="top">
          ¡Inscríbete acá!
        </Button>
      </div>
      {/* <Container>
        <h1>¿Prefieres volver más tarde ?</h1>
        <p>
          Déjanos tu email y te enviaremos toda la información necesaria para que decidas con calma.
        </p>
        {userId ? (
          <p className={styles.success}>¡Ya estas registrado!</p>
        ) : (
          <div className={styles.form}>
            <CodeLogin
              onLogin={() => showMessage('Te has registrado correctamente')}
              label="Ingresa tu correo"
            />
          </div>
        )}
      </Container> */}
    </div>
  )
}
