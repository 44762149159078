import React from 'react'
import styles from './styles.css'
import Contact from './Contact'
import Legal from './Legal'

export default function Footer(props) {
  return (
    <div className={styles.container}>
      {/* <Contact /> */}
      <Legal />
    </div>
  )
}
