import React from 'react'
import styles from './styles.css'
import useQuery from 'apollo-hooks/lib/useApolloQuery'
import gql from 'graphql-tag'
import Button from 'orionsoft-parts/lib/components/Button'
import Card from './Card'

export default function Cards(props) {
  const {me} = useQuery({
    query: gql`
      query {
        me {
          _id
          cards {
            _id
            title
            comments
            campaign {
              _id
              name
            }
            step {
              name
              cardName
            }
          }
        }
      }
    `
  })

  if (!me.cards.length) {
    return (
      <div className="col-xs-offset-5">
        <Button to="/perfil/referidos" primary >Refiere a alguien</Button>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className="row">
        {me.cards.map(card => (
          <div key={card._id} className="col-xs-12 col-sm-4">
            <Card card={card} />
          </div>
        ))}
      </div>
    </div>
  )
}
