import React from 'react'
import styles from './styles.css'
import PaginatedList from 'App/components/Crud/List'

export default function List(props) {
  return (
    <div className={styles.container}>
      <PaginatedList
        title="Mis referidos"
        name="executiveReferrals"
        canUpdate
        fields={[
          {title: 'Nombre', name: 'data', render: ({data}) => data.firstName},
          {title: 'Apellido', name: 'data', render: ({data}) => data.lastName},
          {title: 'Estado', name: 'step.name'}
        ]}
        allowSearch
        basePath={`/perfil/ejecutivo`}
      />
    </div>
  )
}
