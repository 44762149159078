import React from 'react'
import styles from './styles.css'
import Container from 'orionsoft-parts/lib/components/Container'
import Button from 'orionsoft-parts/lib/components/Button'
import BannerRefer from '../../Banner'

export default function Welcome(props) {
  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.content}>
          <div className={styles.title}>
            <img src="/newImages/referidos_auto.png" alt="Programa de referidos" />
            <h1>
              ¡Conoce los <b>Seguros de Auto</b> que mejor se adaptan a las necesidades de tu
              referido!
            </h1>
          </div>
          <div className={styles.steps}>
            <div className="row">
              <div className="col-xs-12 col-md-4">
                <div className={styles.step}>
                  <div className={styles.imageContainer}>
                    <img src="/newImages/cobertura_basica.png" alt="Cobertura básica" />
                  </div>
                  <p>
                    Pérdida Total + Responsabilidad Civil, cobertura para grandes eventualidades.
                  </p>
                </div>
              </div>
              <div className="col-xs-12 col-md-4">
                <div className={styles.step}>
                  <div className={styles.imageContainer}>
                    <img src="/newImages/cobertura_kilometro.png" alt="Auto por kilómetro" />
                  </div>
                  <p>Es un seguro de cobertura completa, con tarifa desde $11.990.</p>
                </div>
              </div>
              <div className="col-xs-12 col-md-4">
                <div className={styles.step}>
                  <div className={styles.imageContainer}>
                    <img src="/newImages/cobertura_completa.png" alt="Cobertura completa" />
                  </div>
                  <p>Seguro particular con obertura para todo evento, daños propios y terceros.</p>
                </div>
              </div>
            </div>
            <div className={styles.infoContainer}>
              <div className={styles.info}>
                <img src="/newImages/check.png" />
                <p>
                  Refiere un interesado en contratar un <b>Seguro de auto</b> desde tu perfil,
                  puedes ser tú mismo y a la vez ganas lucas.
                </p>
              </div>
              <div className={styles.info}>
                <img src="/newImages/check.png" />
                <p>
                  Si tu referido contrata uno de estos seguros <b>ganas $25.000 bruto.</b>
                </p>
              </div>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <Button primary to="/referidos-auto/empezar">
              Empezar
            </Button>
          </div>
        </div>
      </Container>
      <BannerRefer />
    </div>
  )
}
