import React from 'react'
import styles from './styles.css'
import Container from 'orionsoft-parts/lib/components/Container'

export default function Contact(props) {
  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.content}>
          <a target="blank" href="https://wa.me/56944254160">
            <p>¿Tienes más dudas? escríbenos</p>{' '}<img src='/newImages/Whatsapp-home.png'/>{' '}
              +569 4425 4160
          </a>
        </div>
      </Container>
    </div>
  )
}
