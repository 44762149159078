import ReactGA from 'react-ga'
import useEffect from 'App/hooks/useEffect'
import useRef from 'App/hooks/useRef'
import trackPageView from 'App/helpers/track/trackPageView'

const code = 'UA-252740446-1'

export default function GoogleAnalytics(props) {
  const {pathname} = props
  const pathnameRef = useRef(pathname)

  useEffect(() => {
    if (pathname === pathnameRef.current) return
    pathnameRef.current = pathname
    trackPageView(pathname)
  }, [pathname])

  useEffect(() => {
    ReactGA.initialize(code)
    trackPageView(pathname)
  }, [])

  return null
}
