export default {
  stringTooShort: ({label}) => `${label} is too short`,
  notInSchema: ({label}) => `${label} is not in the schema`,
  required: ({label}) => `${label} is required`,
  unknownFieldType: ({label}) => `${label} is an unkown field type`,
  notAnArray: ({label}) => `${label} is not an array`,
  notAnObject: ({label}) => `${label} is not an object`,
  notAString: ({label}) => `${label} is not a string`,
  notADate: ({label}) => `${label} is not a date`,
  notAnInteger: ({label}) => `${label} is not an integer`,
  notANumber: ({label}) => `${label} is not a number`,
  notAnId: ({label}) => `${label} is not a valid ID`,
  stringTooLong: ({label}) => `${label} is too long`,
  numberTooSmall: ({label}) => `${label} is too small`,
  numberTooBig: ({label}) => `${label} is too big`,
  notABoolean: ({label}) => `${label} is not a boolean`,
  notAnEmail: ({label}) => `${label} is not an email`,
  notUnique: ({label}) => `${label} is not unique`,
  notFound: ({label}) => `${label} not found`,
  invalid: ({label}) => `${label} is not a valid value`,
  mustStartWithPlus: 'The phone must start with +',
  emailExists: 'You already have an account',
  incorrectPassword: 'Incorrect password',
  noPassword:
    'Your account doesn\'t have a password, please go to "forgot password" to create a new one',
  userNotFound: 'You do not have an account here',
  invalidPhoneLength: 'The length of the phone number is incorrect',
  productWithoutImage: 'Theres a product without image',
  addressExists: 'Address exists',
  mustWaitToRequestLoginCode: 'You must wait 20 seconds to request the code again',
  incorrectLoginCode: 'The code is incorrect',
  incorrectLoginCodeToken: 'The code is not valid. Please generate a new code',
  loginCodeExpired: 'The code expired. Request a new one',
  loginCodeLocked: 'The code was blocked. Generate a new code to continue'
}
