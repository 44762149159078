import React from 'react'
import styles from './styles.css'
import Section from 'App/components/Section'
import Button from 'orionsoft-parts/lib/components/Button'
import AutoForm from 'App/components/AutoForm'
import withMessage from 'orionsoft-parts/lib/decorators/withMessage'
import PropTypes from 'prop-types'
import withGraphQL from 'react-apollo-decorators/lib/withGraphQL'
import gql from 'graphql-tag'
import Translate from 'App/i18n'

const fragment = gql`
  fragment setUserPaymentDataFragment on User {
    _id
    paymentData {
      bankId
      bankAccountType
      bankAccountNumber
    }
  }
`

@withGraphQL(gql`
  query getMyPaymentData {
    user: me {
      ...setUserPaymentDataFragment
    }
  }
  ${fragment}
`)
@withMessage
export default class PaymentData extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    showMessage: PropTypes.func
  }

  state = {}

  render() {
    if (!this.props.user) return

    return (
      <div className={styles.container}>
        <Section
          top
          description="Mantén tus datos actualizados para realizar los pagos de tus referidos que cierran negocio con nuestros ejecutivos. Recuerda que una vez enviada la boleta de honorarios son 20 días hábiles para que se habilite el pago.">
          <h1 className={styles.formTitle}>Datos  para realizar tu pago</h1>
          <br/>
          <br/>
          <AutoForm
            mutation="setUserPaymentData"
            ref="form"
            doc={{userId: this.props.user._id, paymentData: this.props.user.paymentData}}
            onSuccess={() => this.props.showMessage('Datos actualizados')}
            fragment={fragment}
            omit="userId"
          />
          <br />
          <Button onClick={() => this.refs.form.submit()} primary>
            <Translate tr="global.save" />
          </Button>
        </Section>
      </div>
    )
  }
}
