import Button from 'orionsoft-parts/lib/components/Button';
import React from 'react';
import styles from './styles.css';

export default function ReferProductCard({ description, description2, img, foot, url, url2 }) {
  console.log(description)
  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <img src={img} />
      </div>
      <div className={styles.description}>
        <span className={styles.descriptionItem}><img className={styles.check} src="/newImages/check.png"/><p>{description}</p></span>
        <span className={styles.descriptionItem}><img className={styles.check} src="/newImages/check.png"/><p>{description2}</p></span>
      </div>
      <div className={styles.foot}>
        <h1>{foot}</h1>
      </div>
      <div className={styles.button}>
        <Button primary to={url}>REFERIR</Button>
      </div>
      <div>
        <a className={styles.link} href={url2}>Ver más detalles {">>"}</a>
      </div>
    </div>
  )
}