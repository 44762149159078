import React from 'react'
import styles from './styles.css'
import Hero from './Hero'
import Start from './Start'
import Faq from './Faq'
// import Later from './Later'
import Contact from './Contact'
import Banner from './Banner'

export default class Home extends React.Component {
  static propTypes = {}

  render() {
    return (
      <div className={styles.container}>
        <Hero />
        <Start />
        <Faq />
        <Contact />
        <Banner />
      </div>
    )
  }
}
