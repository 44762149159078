import React from 'react'
import AutoForm from 'App/components/AutoForm'
import {Field} from 'simple-react-form'
import Text from 'orionsoft-parts/lib/components/fields/Text'
import Button from 'orionsoft-parts/lib/components/Button'
import useRef from 'App/hooks/useRef'
import useState from 'App/hooks/useState'

export default function RequestCode(props) {
  const button = useRef()
  const [email, setEmail] = useState()

  const onError = error => {
    console.log('on error', error)
    if (error.email === 'userNotFound') {
      props.setEmail(email)
    }
  }

  const onSuccess = token => {
    props.setToken(token)
    props.setEmail(email)
  }

  return (
    <AutoForm
      mutation="requestLoginCode"
      buttonRef={button}
      onSuccess={onSuccess}
      doc={{email}}
      onChange={({email}) => setEmail(email)}
      onValidationError={onError}>
      <div className="label">{props.label || 'Email'}</div>
      <Field fieldName="email" type={Text} fieldType="email" placeholder="Email" />
      <br />
      <Button loading={props.loading} primary ref={button}>
        Continuar
      </Button>
    </AutoForm>
  )
}
