export default {
  profile: 'Perfil',
  profileDescription: 'Mantén siempre actualizada tu información personal',
  yourProfileWasSaved: 'Tu perfil fue guardado',
  twoFactorAuthentication: 'Autentificación de dos factores',
  twoFactorDescription:
    'La autentificación de dos factores (2FA) agrega una capa adicional de protección más alla de las contraseñas',
  twoFactorNowYouMustConfirm: 'Ahora debes confirmar el código desde tu app',
  twoFactorEnabled: 'La autentificación de dos factores fue activada',
  enableTwoFactor: 'Activar autentificación de dos factores',
  twoFactorStep1: '1. Descarga la app de autentificación desde el AppStore o PlayStore',
  twoFactorStep2: '2. Escanea el código QR con la app',
  twoFactorOrManuallyAddTheCode: 'O agrega el código manualmente',
  twoFactorStep3: '3. Escribe el código de 6 digitos para confirmar',
  twoFactorDisabled: 'La autentificación de dos factores se ha deshabilitado',
  twoFactorActivated: 'Two factor authentication activated',
  twoFactorYourAccountIsSafer: 'Tu cuenta es más segura',
  currentPassword: 'Contraseña actual',
  newPassword: 'Nueva contraseña',
  confirmTheNewPassword: 'Confirma la nueva contraseña',
  confirmYourPassword: 'Confirma tu contraseña',
  repeatYourNewPassword: 'Repite tu nueva contraseña',
  changePassword: 'Cambiar contraseña',
  changePasswordDescription: 'Cambia tu contraseña',
  title: 'Ajustes'
}
