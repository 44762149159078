import React from 'react'
import styles from './styles.css'
import CodeLogin from 'App/Pages/Auth/CodeLogin'
import Container from 'orionsoft-parts/lib/components/Container'

export default function Login(props) {
  return (
    <div className={styles.container}>
      <Container size="small">
        <h1>Ingresa tu email</h1>
        <br />
        <CodeLogin onLogin={() => {}} />
      </Container>
    </div>
  )
}
