import React from 'react'
import styles from './styles.css'
import Text from 'orionsoft-parts/lib/components/fields/Text'

export default function Phone(props) {
  const val = (props.value || '').replace(/^\+56/, '')
  const onChange = text => {
    const numbers = text.replace(/\D/g, '')
    if (!numbers) {
      props.onChange(null)
    } else {
      props.onChange('+56' + numbers)
    }
  }
  return (
    <div className={styles.container}>
      <div className={styles.digit}>+56</div>
      <Text {...props} value={val} onChange={onChange} />
    </div>
  )
}
