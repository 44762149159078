import React from 'react'
import styles from './styles.css'
import {Route, Switch, Redirect} from 'react-router-dom'
import Start from './Start'
import useForceLogin from 'App/hooks/useForceLogin'
import Info1 from './Info1'
import Info2 from './Info2'
import Info3 from './Info3'
import Success from './Success'
import Welcome from './Welcome'
import Faq from './Faq'
import PreStart from './PreStart'

export default function Referrals(props) {
  useForceLogin()
  return (
    <div className={styles.container}>
      <Switch>
        <Route path="/referidos/info-1" component={Info1} />
        <Route path="/referidos/info-2" component={Info2} />
        <Route path="/referidos/info-3" component={Info3} />
        <Route path="/referidos/referir" component={() => <Redirect to="/referir/referir" />} />
        <Route path="/referidos/exito" component={Success} />
        <Route path="/referidos/empezar" component={() => <Redirect to="/referir/empezar" />} />
        <Route path="/referidos/faq" component={Faq} />
        <Route path="/referidos/vida" component={Welcome} />
        <Route path="/referidos" component={PreStart} />
      </Switch>
    </div>
  )
}
