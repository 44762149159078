import React from 'react'
import AutoForm from 'App/components/AutoForm'
import {Field} from 'simple-react-form'
import Text from 'orionsoft-parts/lib/components/fields/Text'
import Button from 'orionsoft-parts/lib/components/Button'
import useRef from 'App/hooks/useRef'
import useMutation from 'apollo-hooks/lib/useMutation'
import gql from 'graphql-tag'
import useMessage from 'App/hooks/useMessage'
import useState from 'App/hooks/useState'
import SixDigitSmallInput from 'App/components/fields/SixDigitSmallInput'
import useRouter from 'App/hooks/useRouter'

export default function LoginWithCode(props) {
  const {token, onSuccess, setToken, email} = props
  const button = useRef()
  const {history} = useRouter()
  const [loading, setLoading] = useState()
  const showMessage = useMessage()

  const mutate = useMutation(gql`
    mutation requestLoginCode($email: String) {
      token: requestLoginCode(email: $email)
    }
  `)

  const requestAgain = async () => {
    setLoading(true)
    try {
      const {token} = await mutate({email})
      setToken(token)
      showMessage('Enviamos el código a tu correo')
    } catch (error) {
      showMessage(error)
    }
    setLoading(false)
  }

  return (
    <AutoForm
      mutation="loginWithCode"
      doc={{email, token}}
      omit="token"
      buttonRef={button}
      onSuccess={onSuccess}>
      <div className="label">{props.label || 'Email'}</div>
      <Field fieldName="email" disabled type={Text} />
      <div className="label">Código enviado a tu correo</div>
      <Field
        fieldName="code"
        type={SixDigitSmallInput}
        digits={6}
        onReady={code => {
          if (code.toUpperCase() === 'PASSWO') {
            history.replace('/login-password')
          } else {
            button.current.onClick()
          }
        }}
      />
      <div className="description">
        Te enviamos un email con el código para entrar.{' '}
        <a onClick={requestAgain}>Enviar nuevo código</a>
      </div>
      <br />
      <Button loading={loading} primary ref={button}>
        Entrar
      </Button>
    </AutoForm>
  )
}
