import React from 'react'
import styles from './styles.css'
import AsyncSelect from 'react-select/lib/Async'

export default function Place(props) {
  const onChange = value => {
    props.onChange(value.map(val => val.value))
  }

  const search = async inputValue => {
    return [{value: inputValue, label: inputValue}]
  }

  return (
    <div className={styles.container}>
      <div className="label">{props.label}</div>
      <AsyncSelect
        {...props}
        isMulti
        classNamePrefix="orion-select"
        onChange={onChange}
        loadOptions={search}
        value={(props.value || []).map(value => ({value, label: value}))}
        isClearable
      />
      <div className="description">{props.description}</div>
      <div className="os-input-error">{props.errorMessage}</div>
    </div>
  )
}
