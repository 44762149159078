import React from 'react'
import styles from './styles.css'
import InfoTable from 'App/components/InfoTable'

export default function Data(props) {
  const {card} = props
  const {data} = card
  return (
    <div className={styles.container}>
      <InfoTable
        title="Información"
        data={{
          Nombre: data.firstName,
          Apellido: data.lastName,
          'Segundo apellido': data.secondLastName,
          Teléfono: data.phone,
          Email: data.email,
          Región: data.region,
          Ciudad: data.city,
          Relación: data.relationship,
          Ejecutivos: data.executives,
          'Fecha de referido': card.createdAt,
          Referidor: card.createdByLabel
        }}
      />
    </div>
  )
}
