import isObject from 'lodash/isObject'
import isNumber from 'lodash/isNumber'
import numeral from 'numeral'
import moment from 'moment'
import isArray from 'lodash/isArray'
import React from 'react'
import isBoolean from 'lodash/isBoolean'

export default function getText(value) {
  if (isArray(value)) {
    return value
      .map(item => getText(item))
      .filter(text => !!text)
      .join(', ')
  }
  if (isObject(value)) {
    if (React.isValidElement(value)) return value
    return
  }

  if (isNumber(value)) return numeral(value).format('0,0.[000000]')

  if (isBoolean(value)) {
    return value ? 'Si' : 'No'
  }

  const date = moment(value, moment.ISO_8601, true)
  if (date.isValid()) return date.format('HH:mm:ss - DD/MM/YYYY')

  return value
}
