import React from 'react'
import styles from '../step.css'
import Button from 'orionsoft-parts/lib/components/Button'
import Container from 'orionsoft-parts/lib/components/Container'

export default function Info1(props) {
  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.stepInfoContainer}>
          <div className="row">
            <div className="col-xs-3 col-sm-4">
              <div className={styles.imageNeed} />
            </div>
            <div className="col-xs-9 col-sm-8">
              <div className={styles.stepInfo}>
                <h1>Estás apunto de ingresar a un referido</h1>
                <p>
                  Puedes tener algún conocido que quiera financiar la compra de su vivienda nueva o
                  usada, o refinanciar la deuda que tiene con otra institución.
                </p>
                <p>Cubre hasta el 80% del valor de la propiedad.</p>
                <p>Puede elegir el plazo en el quiera pagar su crédito, entre 8 y 25 años.</p>
                <Button to="/referidos-hipotecario/info-2" danger>
                  Siguiente
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
