import React from 'react'
import styles from './styles.css'
import Container from 'orionsoft-parts/lib/components/Container'
import {Link} from 'react-router-dom'

export default function Legal(props) {
  return (
    <div className={styles.container}>
      <Container>
        <div className="row">
          <div className="col-xs-12 col-sm-5">
            <div className={styles.icons}>
              <img
                className={styles.iconMCL}
                src="/images/mcl-small.png"
                alt="Logo Mejor con Lucas"
              />
              <img
                className={styles.iconConsorcio}
                src="/images/consorcio-small.png"
                alt="Logo Consorcio"
              />
            </div>
          </div>
          <div className="col-xs-12 col-sm-7">
            <div className={styles.linksFooter}>
              <div className={styles.social}>
                <a target="blank" href="https://wa.me/56944254160">
                  <img
                    className={styles.iconSocial}
                    src="/newImages/Whatsapp-icono.png"
                    alt="Whatsapp"
                  />
                </a>
                <a href="https://www.facebook.com/mejorconlucas" target="blank">
                  <img
                    className={styles.iconSocial}
                    src="/newImages/Facebook-icono.png"
                    alt="Facebook"
                  />
                </a>
                <a href="https://www.instagram.com/mejorconlucas_cl/" target="blank">
                  <img
                    className={styles.iconSocial}
                    src="/newImages/Instagram-icono.png"
                    alt="Instagram"
                  />
                </a>
                <a href="https://www.linkedin.com/company/mejorconlucas/" target="blank">
                  <img
                    className={styles.iconSocial}
                    src="/newImages/LinkedIn-icono.png"
                    alt="Linkedin"
                  />
                </a>
                <a href="https://www.tiktok.com/@mejorconlucas" target="blank">
                  <img
                    className={styles.iconSocial}
                    src="/newImages/Tiktok-icono.png"
                    alt="Tiktok"
                  />
                </a>
              </div>
              <div className={styles.links}>
                <a href="/documents/terminosycondiciones.pdf" target="blank">
                  Términos de Uso y Condiciones Programa Mejor con Lucas
                </a>
                <div className={styles.pya}>
                  <a
                    className={styles.pp}
                    target="blank"
                    href="/documents/politicas_de_privacidad.pdf">
                    Politicas de Privacidad
                  </a>
                  <Link to="/referidos">Acerca del Programa Mejor con Lucas</Link>
                </div>
                <div className={styles.pya}>
                  <a
                    className={styles.pp}
                    href="https://www.youtube.com/watch?v=z4kxaa2vgUk&ab_channel=LaUmejorconLucas"
                    target="blank">
                    Cómo hacer una boleta de honorarios
                  </a>
                  <a
                    href="https://www.youtube.com/watch?v=vYXdnBkmUlc&ab_channel=LaUmejorconLucas"
                    target="blank">
                    Inicio actividades online
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
