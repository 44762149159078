import React from 'react'
import styles from '../step.css'
import Button from 'orionsoft-parts/lib/components/Button'
import Container from 'orionsoft-parts/lib/components/Container'

export default function Info1(props) {
  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.stepInfoContainer}>
          <div className="row">
            <div className="col-xs-3 col-sm-4">
              <div className={styles.imageNeed} />
            </div>
            <div className="col-xs-9 col-sm-8">
              <div className={styles.stepInfo}>
                <h1>Debe tener las siguientes condiciones:</h1>
                <p>
                  1. La(s) persona(s) que refieras debe ser un conocido tuyo. No refieras a
                  extraños.
                </p>
                <p>
                  2. La persona que refieras debe poseer ingresos. Idealmente, sobre un millón de
                  pesos.
                </p>
                <p>3. La persona debe ser mayor de 26 años.</p>
                <Button to="/referidos/info-3" primary>
                  Siguiente
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
