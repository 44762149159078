import React from 'react'
import styles from './styles.css'
import useQuery from 'apollo-hooks/lib/useApolloQuery'
import gql from 'graphql-tag'

export default function Images(props) {
  const {images} = useQuery({
    query: gql`
      query getImages($filesIds: [ID]) {
        images(filesIds: $filesIds) {
          _id
          url
          name
          colors {
            darkVibrant
            lightMuted
          }
        }
      }
    `,
    variables: {filesIds: props.filesIds}
  })

  if (!images || !images.length) return null
  return (
    <div className={styles.container}>
      {images.map(image => (
        <div className={styles.imageContainer} key={image._id}>
          <img className={styles.image} src={image.url} />
          {image.colors ? (
            <div
              className={styles.colors}
              style={{color: image.colors.darkVibrant, backgroundColor: image.colors.lightMuted}}
            />
          ) : null}
        </div>
      ))}
    </div>
  )
}
