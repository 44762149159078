import React from 'react'
import {Route, Switch} from 'react-router-dom'
import Home from './Home'
import Settings from './Settings'
import Layout from './Layout'
import Referrals from './Referrals'
import HouseReferrals from './HouseReferrals'
import APV10 from './APV10'
import CarReferrals from './CarReferrals'
import HouseReferralsDestacame from './HouseReferralsDestacame'
import CarReferralsDestacame from './CarReferralsDestacame'
import HouseReferralsUhomie from './HouseReferralsUhomie'
import ReferAll from './ReferAll'

export default class MainHome extends React.Component {
  static propTypes = {}

  render() {
    return (
      <Layout>
        <Switch>
          <Route path="/perfil" component={Settings} />
          <Route path="/referidos" component={Referrals} />
          <Route path="/referidos-hipotecario" component={HouseReferrals} />
          <Route path="/referidos-hipotecario-destacame" component={HouseReferralsDestacame} />
          <Route path="/referidos-hipotecario-uhomie" component={HouseReferralsUhomie} />
          <Route path="/referidos-apv10" component={APV10} />
          <Route path="/referidos-auto" component={CarReferrals} />
          <Route path="/referidos-auto-destacame" component={CarReferralsDestacame} />
          <Route path="/referir" component={ReferAll} />
          <Route path="/" component={Home} />
        </Switch>
      </Layout>
    )
  }
}
