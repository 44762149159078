import React from 'react'
import styles from '../step.css'
import Container from 'orionsoft-parts/lib/components/Container'
import useRef from 'App/hooks/useRef'
import Button from 'orionsoft-parts/lib/components/Button'
import useRouter from 'App/hooks/useRouter'
import CardForm from 'App/components/CardForm'
import useQuery from 'apollo-hooks/lib/useApolloQuery'
import gql from 'graphql-tag'

export default function Start(props) {
  const button = useRef()
  const {history} = useRouter()
  const {me} = useQuery({
    query: gql`
      query {
        me {
          _id
          email
          profile {
            firstName
            lastName
            rut
            phone
          }
          paymentData {
            bankId
            bankAccountType
            bankAccountNumber
          }
        }
      }
    `
  })

  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.content}>
          <h2>Información</h2>
          <p>Ingresa los datos del auto</p>
          <div>
            <CardForm
              mutation="createCard"
              buttonRef={button}
              campaignId="carDestacame"
              onSuccess={() => {
                history.push('/referidos-auto-destacame/exito')
              }}
              omit={['data.firstName', 'data.lastName', 'data.rut', 'data.email', 'data.phone']}
              doc={{
                campaignId: 'carDestacame',
                data: {
                  ...me.profile,
                  email: me.email
                }
              }}
            />
          </div>
          <br />
          <Button ref={button} primary>
            Siguiente
          </Button>
        </div>
      </Container>
    </div>
  )
}
