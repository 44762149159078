import React from 'react'
import useQuery from 'apollo-hooks/lib/useApolloQuery'
import gql from 'graphql-tag'
import Button from 'orionsoft-parts/lib/components/Button'
import useRouter from 'App/hooks/useRouter'

export default function Executive(props) {
  const {history} = useRouter()
  const {me} = useQuery({
    query: gql`
      query getMe {
        me {
          _id
          isExecutive
        }
      }
    `
  })
  if (!me || !me.isExecutive) return null
  if (history.location.pathname.startsWith('/perfil/ejecutivo')) return null
  return (
    <Button danger to="/perfil/ejecutivo" style={{marginLeft: 10}}>
      Panel de Ejecutivo
    </Button>
  )
}
