import React from 'react'
import styles from '../step.css'
import Container from 'orionsoft-parts/lib/components/Container'
import useRef from 'App/hooks/useRef'
import Button from 'orionsoft-parts/lib/components/Button'
import useRouter from 'App/hooks/useRouter'
import CardForm from 'App/components/CardForm'

export default function Start(props) {
  const button = useRef()
  const {history} = useRouter()

  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.content}>
          <h2>Ingresa un nuevo referido</h2>
          <div>
            <p>
              <b>Importante: No puedes ingresar datos de personas que no conozcas</b>
            </p>
          </div>
          <div className="divider" />
          <h2>Datos de contacto</h2>
          <p>Estos son los datos mínimos para poder ponernos en contactos con tu referido.</p>
          <div>
            <CardForm
              mutation="createCard"
              buttonRef={button}
              campaignId="all"
              onSuccess={() => {
                history.push('/referir/exito')
              }}
              doc={{campaignId: 'all', data: {}}}
            />
          </div>
          <br />
          <Button ref={button} primary>
            Siguiente
          </Button>
        </div>
      </Container>
    </div>
  )
}
