import React from 'react'
import LoggedIn from '../LoggedIn'
import {setSession} from '@orion-js/graphql-client'
import useUserId from 'App/hooks/useUserId'
import useState from 'App/hooks/useState'
import LoginWithCode from './LoginWithCode'
import RequestCode from './RequestCode'
import Register from './Register'

export default function Login(props) {
  const userId = useUserId()
  const [token, setToken] = useState()
  const [email, setEmail] = useState()

  const onSuccess = async session => {
    await setSession(session)
    await props.onLogin()
  }

  if (!props.loading && userId) return <LoggedIn />

  if (token) {
    return (
      <LoginWithCode
        {...props}
        token={token}
        onSuccess={onSuccess}
        setToken={setToken}
        email={email}
      />
    )
  }

  if (email) {
    return <Register {...props} email={email} onSuccess={onSuccess} />
  }

  return <RequestCode {...props} setEmail={setEmail} setToken={setToken} />
}
