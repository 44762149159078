import React from 'react'
import useQuery from 'apollo-hooks/lib/useApolloQuery'
import gql from 'graphql-tag'
import useEffect from 'App/hooks/useEffect'
import ReactPixel from 'react-facebook-pixel'
import values from 'lodash/values'
import GA from './GA'
import trackPageView from 'App/helpers/track/trackPageView'
import useRouter from 'App/hooks/useRouter'

const code = '156249929033200'

export default function(props) {
  const {location} = useRouter()
  const {pathname} = location

  const {me} = useQuery({
    query: gql`
      query {
        me {
          _id
          createdAt
          email
          profile {
            phone
            firstName
            lastName
          }
        }
      }
    `
  })

  const pixelUser = me
    ? {
        em: me.email,
        ph: me.profile.phone ? me.profile.phone.replace('+', '') : null,
        fn: me.profile.firstName,
        ln: me.profile.lastName
      }
    : null

  useEffect(() => {
    ReactPixel.init(code, pixelUser)
    trackPageView(pathname)
  }, values(pixelUser))

  return <GA pathname={pathname} />
}
