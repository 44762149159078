import useEffect from 'App/hooks/useEffect'
import React from 'react'
import useMutate from 'apollo-hooks/lib/useMutate'
import gql from 'graphql-tag'
import useMessage from 'App/hooks/useMessage'
import Loading from 'orionsoft-parts/lib/components/Loading'
import styles from './styles.css'

export default function Register(props) {
  const {onSuccess, email} = props
  const mutate = useMutate()
  const showMessage = useMessage()
  const createAccount = async () => {
    try {
      const {result} = await mutate({
        mutation: gql`
          mutation createUser($email: String) {
            result: createUser(email: $email, profile: {}) {
              _id
              publicKey
              secretKey
              createdAt
              nonce
              lastCall
              userId
              locale
              roles
              emailVerified
            }
          }
        `,
        variables: {email}
      })
      onSuccess(result)
    } catch (error) {
      showMessage(error)
    }
  }
  useEffect(() => {
    createAccount()
  }, [])

  return (
    <div className={styles.container}>
      <br />
      <div>
        <Loading />
      </div>
      <br />
      <div>Creando cuenta...</div>
    </div>
  )
}
