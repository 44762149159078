import React from 'react'
import styles from './styles.css'
import keys from 'lodash/keys'
import getText from './getText'
import getLabel from './getLabel'

export default function SideInformation(props) {
  const {data, title, onClick} = props
  const items = []
  for (const key of keys(data)) {
    const value = getText(data[key])
    if (key === '__typename') continue
    if (value) {
      items.push({label: getLabel(key), value})
    }
  }

  const content = (
    <div className={styles.container}>
      <table>
        <tbody>
          {items.map((item, index) => (
            <tr key={index}>
              <td className={styles.label}>{item.label}</td>
              <td className={styles.value}>{item.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.children}>{props.children}</div>
    </div>
  )
  return title ? (
    <div className={styles.containerWithTitle}>
      <div className={styles.title}>
        <div className={styles.titleText}>{title}</div>
        {onClick ? (
          <div className={styles.edit} onClick={onClick}>
            Editar
          </div>
        ) : null}
      </div>
      {content}
    </div>
  ) : (
    content
  )
}
