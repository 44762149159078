import createAutoform from 'orionjs-react-autoform'
import fields from '../fields'
import translate from 'App/i18n/translate'

const Autoform = createAutoform({
  fields,
  onError: error => alert(error.message),
  getErrorText: (code, field) => {
    field.label = field.label === 'This field' ? 'Este campo' : field.label
    return translate(`errors.${code}`, field)
  },
  loading: 'loading...'
})

export default Autoform
