import React from 'react'
import styles from '../step.css'
import Button from 'orionsoft-parts/lib/components/Button'
import Container from 'orionsoft-parts/lib/components/Container'

export default function Info1(props) {
  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.stepInfoContainer}>
          <div className="row">
            <div className="col-xs-3 col-sm-4">
              <div className={styles.imageNeed} />
            </div>
            <div className="col-xs-9 col-sm-8">
              <div className={styles.stepInfo}>
                <h1>Debe tener las siguientes condiciones:</h1>
                <p>
                  1. La(s) persona(s) que refieras debe ser un conocido tuyo. No refieras a
                  extraños.
                </p>
                <p>
                  2. La persona que refieras debe poseer ingresos. Idealmente, sobre un millón de
                  pesos.
                </p>
                <p>
                  3. Dependiendo del monto del crédito que contrate tu referido podrás ganar hasta
                  $150.000 bruto.
                </p>
                <p>
                  4. La persona que refieras deberá presentar una documentación que le solicitará el
                  ejecutivo.
                </p>
                <Button to="/referidos-hipotecario/info-3" danger>
                  Siguiente
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
