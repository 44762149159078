import ArrayComponent from 'orionsoft-parts/lib/components/fields/ArrayComponent'
import Text from 'orionsoft-parts/lib/components/fields/Text'
import NumberField from 'orionsoft-parts/lib/components/fields/numeral/Number'
import DateText from 'orionsoft-parts/lib/components/fields/DateText'
import Select from 'orionsoft-parts/lib/components/fields/Select'
import Checkbox from 'orionsoft-parts/lib/components/fields/Checkbox'
import Textarea from 'orionsoft-parts/lib/components/fields/Textarea'
import ObjectField from './ObjectField'
import SixDigitInput from './SixDigitInput'
import FileManager from './FileManager'
import MultipleMoney from './MultipleMoney'
import File from './File'
import DateArray from './DateArray'
import SelectImage from './SelectImage'
import Money from 'orionsoft-parts/lib/components/fields/numeral/Money'
import Percentage from 'orionsoft-parts/lib/components/fields/numeral/Percentage'
import Phone from './Phone'
import Image from './Image'
import StringArray from './StringArray'
import Executive from './Executive'
import NextDateTime from './NextDateTime'

export default {
  phone: Phone,
  nextDateTime: NextDateTime,
  executive: Executive,
  percentage: Percentage,
  string: Text,
  textarea: Textarea,
  email: Text,
  fileManager: FileManager,
  file: File,
  image: Image,
  money: Money,
  number: NumberField,
  array: ArrayComponent,
  plainObject: ObjectField,
  dateArray: DateArray,
  boolean: Checkbox,
  date: DateText,
  sixDigit: SixDigitInput,
  multipleMoney: MultipleMoney,
  select: Select,
  '[string]': StringArray,
  selectImage: SelectImage
}
