import React from 'react'
import styles from './styles.css'
import Button from 'orionsoft-parts/lib/components/Button'

export default function BannerRefer() {
  return (
    <div className={styles.bannerContainer}>
      <img
        className={styles.bannerImg}
        src="/newImages/banner_perfil_invitacion.png"
        alt="banner perfil invitación"
      />
      <div className={styles.content}>
        <h1 className={styles.bannerText}>¡Estás a un paso de ganar lucas!</h1>
        <br />
        <Button to="/perfil/referidos" primary>
          ¡Refiere acá!
        </Button>
      </div>
    </div>
  )
}
