import React from 'react'
import styles from './styles.css'
import Container from 'orionsoft-parts/lib/components/Container'
import Button from 'orionsoft-parts/lib/components/Button'
import questions from './questions'
import useRouter from 'App/hooks/useRouter'

export default function Faq(props) {
  const {location} = useRouter()
  const startURL = location.pathname.replace('faq', 'empezar')
  return (
    <div className={styles.container}>
      <Container size="medium">
        <div className={styles.content}>
          <div className={styles.title}>
            <h1>Preguntas frecuentes</h1>
          </div>
          <div className={styles.questions}>
            {questions.map(({title, answer}) => (
              <div key={title} className={styles.question}>
                <h3>{title}</h3>
                <p>{answer}</p>
              </div>
            ))}
          </div>
          <div className={styles.buttons}>
            <Button primary to={startURL}>
              Empezar ahora
            </Button>
          </div>
        </div>
      </Container>
    </div>
  )
}
