import React from 'react'
import styles from './styles.css'
import Container from 'orionsoft-parts/lib/components/Container'
import Button from 'orionsoft-parts/lib/components/Button'
import BannerRefer from '../../Banner'

export default function Welcome(props) {
  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.content}>
          <div className={styles.title}>
            <img src="/newImages/referidos_vida.png" alt="Programa de referidos" />
            <h1>
              ¡Conoce los <b>Seguros de Vida</b> y descubre el que mejor se adapte a las necesidades
              de tu referido!
            </h1>
          </div>
          <div className={styles.steps}>
            <div className="row">
              <div className="col-xs-12 col-md-4">
                <div className={styles.step}>
                  <div className={styles.imageContainer}>
                    <img src="/newImages/vida_conahorro.png" alt="Vida con ahorro" />
                  </div>
                  <p>Un seguro que se adapta a cada persona, entregando protección y ahorro.</p>
                </div>
              </div>
              <div className="col-xs-12 col-md-4">
                <div className={styles.step}>
                  <div className={styles.imageContainer}>
                    <img src="/newImages/vida_conproteccion.png" alt="Vida con protección" />
                  </div>
                  <p>Protección a la medida de cada familia.</p>
                </div>
              </div>
              <div className="col-xs-12 col-md-4">
                <div className={styles.step}>
                  <div className={styles.imageContainer}>
                    <img src="/newImages/seguro_apv.png" alt="Gana lucas" />
                  </div>
                  <p>Ahorras para la vejez y proteges a tu familia.</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.infoContainer}>
            <div className={styles.info}>
              <img src="/newImages/check.png" />
              <p>
                Refiere un interesado en contratar un <b>Seguro de Vida, Seguro con Ahorro o APV</b>{' '}
                desde tu perfil.
              </p>
            </div>
            <div className={styles.info}>
              <img src="/newImages/check.png" />
              <p>
                Si tu referido contrata uno de estos seguros <b>ganas $50.000 bruto.</b>
              </p>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <Button primary to="/referidos/empezar">
              Empezar
            </Button>
          </div>
        </div>
      </Container>
      <BannerRefer />
    </div>
  )
}
