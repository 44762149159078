import React from 'react'
import styles from './styles.css'
import Container from 'orionsoft-parts/lib/components/Container'
import Button from 'orionsoft-parts/lib/components/Button'

export default function Faq(props) {
  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.wrap}>
          <div className={styles.title}>
            <h1>
              Preguntas frecuentes
            </h1>
          </div>
          <div className={styles.content}>
            <h2>
              1. ¿Qué es Mejor Con Lucas?
            </h2>
            <p>
              Mejor con Lucas es un programa de Consorcio para que personas mayores de 18 años puedan tener un ingreso extra desde cualquier lugar. Para esto, deben referir a conocidos que necesiten un Seguro de Auto, Seguro de Vida, Ahorro, APV o Crédito Hipotecario. Nosotros los contactaremos y cuando uno de tus referidos contrate un producto, ganas un bono de $25.000, $50.000  o $150.000 bruto. Tú solamente refieres y Consorcio se encarga del resto.
            </p>
            <h2>
              2. ¿Quiénes pueden participar?
            </h2>
            <p>
              Mayores de 18 años.
            </p>
            <h2>
              3. ¿Quiénes son mis contactos?
            </h2>
            <p>
              Podrás sugerir cualquier contacto (mamá, papá, ti@s, conocidos, etc...) mayor de edad que le pueda interesar adquirir un seguro de auto, seguro de vida, ahorro o crédito hipotecario Consorcio.
            </p>
            <h2>
              4. ¿Qué pasa con mis contactos?
            </h2>
            <p>
              Una vez ingresados tus contactos al programa, el resto del trabajo lo hacen nuestros ejecutivos que los llamarán y agendarán una entrevista con ellos.
            </p>
            <h2>
              5. ¿Qué gano?
            </h2>
            <p>
              Por cada referido enviado por ti que contrate un seguro de vida, ahorro, apv recibirás un bono de $50.000 bruto, en Referidos Hipotecario recibirás un bono entre $50.000 bruto y $150.000 bruto, mientras que el producto Referidos Autos recibirás un bono entre $25.000 bruto.
            </p>
            <h2>
              6. ¿Cómo y cuándo recibo mi pago?
            </h2>
            <p>
              Una vez firmado el contrato del producto adquirido por tu referido se te notificará mediante un mail solicitando una boleta de honorarios. Además, a través de tu cuenta en esta página, podrás hacer el seguimiento del proceso de tus contactos referidos. El/los bonos se pagarán en máximo 20 días hábiles desde que recibimos tu boleta de honorarios. El dinero será transferido o depositado en tu cuenta bancaria (corriente, vista o rut). En el caso de que tu referido contrate un Crédito Hipotecario, se avisará para el pago cuando se firme la escritura (Esto puede demorar mucho tiempo desde que envías tu referido, el proceso de un crédito hipotecario es largo, entre 2 a 6 meses)
            </p>
            <h2>
              7. ¿Cómo realizo mi boleta de honorarios?
            </h2>
            <p>
              Tu boleta de honorarios la debes realizar a través de la página www.sii.cl con los datos que nosotros te enviaremos al mail mediante una notificación cuando tu referido contrate nuestros productos.  Acá Podrás ver los pasos para realizar tu boleta <a>Ver video</a>  (video explicativo de mcl).
            </p>
            <h2>
              8. ¿Qué productos de Consorcio son considerados en este programa?
            </h2>
            <p>
              El programa Mejor con Lucas no paga todos los tipos de seguro, sólo los Seguros de Auto, Seguro de Vida con Ahorro, APV y Crédito Hipotecario.
            </p>
            <h2>
              9. ¿Qué es Consorcio?
            </h2>
            <p>
              Consorcio es un conglomerado de servicios financieros no bancarios y el mayor grupo asegurador de Chile. Ofrecemos entre nuestros productos: Seguros de Vida, Seguros de Automóvil, Seguros con Ahorro, Rentas Vitalicias, APV, Fondos Mutuos, Seguros Colectivos, etc. Más información en <a href='https://www.consorcio.cl'>www.consorcio.cl</a>
            </p>
            <h2>
              10. ¿El referido sabrá quién lo refirió?
            </h2>
            <p>
              El referido sí sabrá quién entregó sus datos cuando lo contacte un ejecutivo de Consorcio.
            </p>
          </div>
        </div>
      </Container>
    </div>
  )
}
