import React from 'react'
import styles from './styles.css'
import Start from '../../Home/Start'

export default function PreStart(props) {
  return (
    <div className={styles.container}>
      <br />
      <br />
      <Start />
    </div>
  )
}
