import React from 'react'
import styles from './styles.css'
import PropTypes from 'prop-types'
import autobind from 'autobind-decorator'
import File from './File'
import AddFile from './AddFile'
import clone from 'lodash/clone'
import Images from './Images'

export default class FileInput extends React.Component {
  static propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    openManager: PropTypes.func,
    multi: PropTypes.bool,
    errorMessage: PropTypes.node,
    label: PropTypes.node,
    description: PropTypes.node
  }

  @autobind
  addFile(fileId) {
    if (this.props.multi) {
      const value = clone(this.props.value || [])
      value.push({_id: fileId})
      this.props.onChange(value)
    } else {
      this.props.onChange({_id: fileId})
    }
  }

  deleteFile(fileId) {
    this.props.onChange(this.props.value.filter(file => file._id !== fileId))
  }

  renderValue() {
    const {multi, value} = this.props
    if (!value) return
    if (multi) {
      return value.map(item => (
        <File key={item} multi fileId={item._id} clear={() => this.deleteFile(item._id)} />
      ))
    } else {
      return <File fileId={value._id} clear={() => this.props.onChange(null)} />
    }
  }

  renderAddMoreFile() {
    const {multi} = this.props
    if (!multi) return
    return [
      <div key="sep" className={styles.addSeparator} />,
      <AddFile key="add" multi addFile={this.addFile} />
    ]
  }

  renderNoValue() {
    const {value, multi} = this.props
    if (multi) return
    if (value) return
    return <AddFile addFile={this.addFile} />
  }

  renderImages() {
    const images = this.props.multi ? this.props.value : [this.props.value]
    const filesIds = (images || []).filter(image => !!image).map(image => image._id)
    if (!filesIds || !filesIds.length) return
    return <Images filesIds={filesIds} />
  }

  render() {
    return (
      <div className={styles.mainContiaber}>
        <div className="label">{this.props.label}</div>
        {this.renderImages()}
        <div className={styles.container} onClick={this.onClick}>
          {this.renderNoValue()}
          {this.renderValue()}
          {this.renderAddMoreFile()}
        </div>
        <div className="description">{this.props.description}</div>
        <div className="error">{this.props.errorMessage}</div>
      </div>
    )
  }
}
