import React from "react";
import styles from './styles.css'
import Button from 'orionsoft-parts/lib/components/Button'

export default function BannerRefer() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>
          ¡Sigue ganando lucas!
        </h1>
        <Button primary to="/perfil/referidos">
          Refiere acá!
        </Button>
      </div>
    </div>
  )
}