import React from 'react'
import styles from '../step.css'
import Button from 'orionsoft-parts/lib/components/Button'
import Container from 'orionsoft-parts/lib/components/Container'

export default function Info1(props) {
  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.stepInfoContainer}>
          <div className="row">
            <div className="col-xs-3 col-sm-4">
              <div className={styles.imageOk} />
            </div>
            <div className="col-xs-9 col-sm-8">
              <div className={styles.stepInfo}>
                <h1>En breve te pediremos los datos de tu referido:</h1>
                <p>
                  Te pediremos a continuación el nombre, apellido, rut y datos de contacto como
                  correo electrónico y teléfono. Tu referido será contactado por las vías de
                  contacto especificadas, de forma no invasiva, de manera que, si la persona no
                  desea contratar nuestros servicios, no insistiremos.
                </p>
                <Button to="/referidos-hipotecario/referir" danger>
                  Siguiente
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
