export default [
  {
    title: '¿Qué es "Mejor Con Lucas"?',
    answer:
      'Somos un programa de Consorcio que permite a los estudiantes tener un ingreso económico extra mediante la recomendación de posibles clientes para nuestros Seguros de Auto, Seguros de Vida, Ahorro o APV y Crédito Hipotecario'
  },
  {title: '¿Quiénes pueden participar?', answer: 'Mayores de 18 años.'},
  {
    title: '¿Quiénes son mis contactos?',
    answer:
      'Podrás sugerir cualquier contacto (mamá, papá, ti@s, etc) mayor de edad que pueda interesarle adquirir un seguro de auto, seguro de vida, ahorro o crédito hipotecario Consorcio.(No se puede referir a uno mismo)'
  },
  {
    title: '¿Qué pasa con mis contactos?',
    answer:
      'Una vez ingresados tus contactos al programa, el resto del trabajo lo hacen nuestros ejecutivos que los llamarán y agendarán una entrevista con ellos.'
  },
  {
    title: '¿Qué gano?',
    answer:
      'Por cada contacto enviado por ti que contrate un seguro de vida, ahorro, apv o crédito hipotecario, recibirás un bono de $50.000 bruto.'
  },
  {
    title: '¿Cómo y cuándo recibo mi pago?',
    answer: `Una vez firmado el contrato del producto adquirido por tu contacto se te notificará
mediante un mail solicitando una boleta de honorarios. Además, a través de tu cuenta
en esta página, podrás hacer el seguimiento del proceso de tus contactos referidos.
El/los bonos se pagarán en máximo 20 días hábiles desde que recibimos tu boleta de
honorarios. El dinero será transferido o depositado en tu cuenta bancaria (corriente,
vista o rut).
En el caso de que tu referido contrate un Crédito Hipotecario, se avisará para el pago
cuando se firme la escritura (Esto puede demorar mucho tiempo desde que envías tu
referido, el proceso de un crédito hipotecario es largo, entre 2 a 6 meses)`
  },
  {
    title: '¿Qué productos de Consorcio son considerados en este programa?',
    answer:
      'El programa Mejor con Lucas no paga todos los tipos de seguro, sólo los Seguros de Auto, Seguro de Vida con Ahorro, APV y Crédito Hipotecario.'
  },
  {
    title: '¿Qué es Consorcio?',
    answer:
      'Consorcio es un conglomerado de servicios financieros no bancarios y el mayor grupo asegurador de Chile. Ofrecemos entre nuestros productos: Seguros de Vida, Seguros de Automóvil, Seguros con Ahorro, Rentas Vitalicias, APV, Fondos Mutuos, Seguros Colectivos, etc. Más información www.consorcio.cl'
  },
  {
    title: '¿El referido sabrá quién lo refirió?',
    answer:
      'El referido si sabrá quién entrego sus datos para que lo contacte un ejecutivo de Consorcio.'
  }
]
