import React from 'react'
import styles from './styles.css'

export default function WhatsappChat() {
  return (
    <div className={styles.container} onClick={() => window.open(`https://wa.me/56944254160`)}>
      <img src="/newImages/Whatsapp-home.png" />
    </div>
  )
}
