import React from 'react'
import styles from '../step.css'
import Container from 'orionsoft-parts/lib/components/Container'

export default function Info1(props) {
  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.stepInfoContainer}>
          <div className="row">
            <div className="col-xs-3 col-sm-4">
              <div className={styles.imageOk} />
            </div>
            <div className="col-xs-9 col-sm-8">
              <div className={styles.stepInfo}>
                <h1>Datos ingresados</h1>
                <p>Ahora debes esperar a que nuestros ejecutivos se contacten contigo</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
