import React from 'react'
import styles from './styles.css'
import List from './List'
import {Route, Switch} from 'react-router-dom'
import Card from './Card'

export default function Executive(props) {
  return (
    <div className={styles.container}>
      <Switch>
        <Route path="/perfil/ejecutivo" exact component={List} />
        <Route path="/perfil/ejecutivo/:referralId" component={Card} />
      </Switch>
    </div>
  )
}
