import getEnv from './getEnv'

const urls = {
  local: `http://${window.location.hostname}:3099`,
  dev: '',
  prod: `https://${window.location.hostname}/api`
}

const env = getEnv()

if (env === 'prod' && window.location.hostname.includes('laumejorconlucas.cl')) {
  window.location.hostname = 'www.mejorconlucas.cl'
}

if (env !== 'local' && window.location.protocol !== 'https:') {
  window.location.protocol = 'https:'
}

export default urls[env]
