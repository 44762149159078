import React from 'react'
import styles from '../step.css'
import style from './styles.css'
import Container from 'orionsoft-parts/lib/components/Container'

export default function Info1(props) {
  return (
    <div className={style.containerSuccess}>
      <Container>
        <div className={styles.stepInfoContainer}>
          <div className={styles.imageOk} />
          <div className={style.stepInfo}>
            <img className={styles.check} src="/newImages/check.png" />
            <h1>¡Referido enviado exitosamente!</h1>
          </div>
        </div>
      </Container>
    </div>
  )
}
