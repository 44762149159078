import React from 'react'
import styles from './styles.css'
import Container from 'orionsoft-parts/lib/components/Container'
import Button from 'orionsoft-parts/lib/components/Button'

export default function Hero(props) {
  return (
    <div>
      <div className={styles.hero}>
        <div className={styles.content}>
          <div className={styles.text}>
            <h1>¿Quieres ganar lucas extra?</h1>
            <h2>¡Estás en el lugar correcto!</h2>
            <p> 
              Gana entre $25.000, $50.000 y $150.000 bruto por referido, sugiérenos conocidos interesados y si contratan nuestros productos ¡Ganas lucas!
            </p>
            <Button to="/referidos" primary>
              ¡Quiero ganar lucas!
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.container}>
      </div>
    </div>
  )
}
