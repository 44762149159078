import React from 'react'
import styles from './styles.css'
import useQuery from 'apollo-hooks/lib/useApolloQuery'
import gql from 'graphql-tag'
import Breadcrumbs from 'App/components/Breadcrumbs'
import Container from 'orionsoft-parts/lib/components/Container'
import Data from './Data'
import Update from './Update'

export default function Card(props) {
  const {card} = useQuery({
    query: gql`
      query getExecutivereferral($referralId: ID) {
        card: executiveReferral(referralId: $referralId) {
          _id
          title
          createdAt
          createdByLabel
          step {
            name
            index
          }
          data
        }
      }
    `,
    variables: {referralId: props.match.params.referralId}
  })
  return (
    <div className={styles.container}>
      <Breadcrumbs past={{'/perfil/ejecutivo': 'Referidos'}}>{card.title}</Breadcrumbs>
      <br />
      <br />
      <Container>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <Data card={card} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <Update card={card} />
          </div>
        </div>
      </Container>
    </div>
  )
}
