import React from 'react'
import styles from './styles.css'
import Container from 'orionsoft-parts/lib/components/Container'
import Button from 'orionsoft-parts/lib/components/Button'
import BannerRefer from '../../Banner'

export default function Welcome(props) {
  return (
    <div className={styles.container}>
      <Container size="medium">
        <div className={styles.content}>
          <div className={styles.title}>
            <img src="/newImages/referidos_apv.png" alt="Programa de referidos hipotecarios" />
            <h2>
              <b>¡Ahorra e invierte</b> para tu futuro <b>el 10% de tu AFP!</b>
            </h2>
          </div>
          <div className={styles.info}>
            <div className={styles.infoLine}>
              <div className={styles.subtitle}>Al contratar un APV recuerda que</div>
            </div>
            <div className={styles.infoLine}>
              <div className={styles.infoIcon}>
                <img src="/newImages/check.png" />
              </div>
              <div className={styles.infoText}>Mejora las expectativas de jubilación</div>
            </div>
            <div className={styles.infoLine}>
              <div className={styles.infoIcon}>
                <img src="/newImages/check.png" />
              </div>
              <div className={styles.infoText}>
                Tiene un seguro de vida para proteger a los beneficiarios de tus clientes
              </div>
            </div>
            <div className={styles.infoLine}>
              <div className={styles.infoIcon}>
                <img src="/newImages/check.png" />
              </div>
              <div className={styles.infoText}>
                Posee ahorro que es inembargable si no son retirados
              </div>
            </div>
            <div className={styles.infoLine}>
              <div className={styles.infoIcon}>
                <img src="/newImages/check.png" />
              </div>
              <div className={styles.infoText}>
                Tiene beneficios tributarios, dependiendo del régimen escogido
              </div>
            </div>
          </div>
          <div className={styles.regins}>
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <h3>Régimen A</h3>
                <p>
                  Al tener régimen tributario A, el cliente recibirá una Bonificación Fiscal de un
                  15% de los aportes que destinados a su Seguro APV con un tope anual de 6 UTM*.
                </p>
              </div>
              <div className="col-xs-12 col-sm-6">
                <h3>Régimen B</h3>
                <p>
                  Al tener régimen tributario B y realizar aportes en su Seguro APV*, los clientes
                  podrán hacer una reliquidación en su Declaración Anual de Impuesto a la Renta,
                  obteniendo una devolución o una rebaja de impuestos, según los aportes realizados
                  y los ingresos obtenidos en el año anterior a la declaración, con un tope de UF 50
                  mensuales o UF 600 anuales.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.info}>
            <div className={styles.infoLine}>
              <div className={styles.infoIcon}>
                <img src="/newImages/check.png" />
              </div>
              <div className={styles.infoText2}>
                Refiere un interesado en <b>guardar el 10% de su AFP en un APV</b> desde tu perfil,
                puedes ser tú mismo y a la vez ganas lucas.
              </div>
            </div>
            <div className={styles.infoLine}>
              <div className={styles.infoIcon}>
                <img src="/newImages/check.png" />
              </div>
              <div className={styles.infoText2}>
                Si tu referido contrata uno de estos seguros <b>ganas $50.000 bruto.</b>
              </div>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <Button primary to="/referidos-apv10/empezar">
              Empezar
            </Button>
          </div>
        </div>
      </Container>
      <BannerRefer />
    </div>
  )
}
