import React from 'react'
import styles from './styles.css'
import Select from 'orionsoft-parts/lib/components/fields/Select'
import useQuery from 'apollo-hooks/lib/useApolloQuery'
import gql from 'graphql-tag'

export default function Executive(props) {
  const {executives} = useQuery({
    query: gql`
      query {
        executives {
          _id
          items {
            _id
            name
            email
          }
        }
      }
    `,
    variables: {}
  })
  const options = executives.items.map(item => ({
    label: `${item.name} <${item.email}>`,
    value: item._id
  }))
  return (
    <div className={styles.container}>
      <Select options={options} {...props} />
    </div>
  )
}
