import React from 'react'
import styles from './styles.css'
import {Route, Switch} from 'react-router-dom'
import Start from './Start'
import useForceLogin from 'App/hooks/useForceLogin'
import Submit from './Submit'
import Success from './Success'

export default function Referrals(props) {
  useForceLogin()

  return (
    <div className={styles.container}>
      <Switch>
        <Route path="/referir/referir" component={Submit} />
        <Route path="/referir/exito" component={Success} />
        <Route path="/referir/empezar" component={Start} />
        <Route path="/referir" component={Start} />
      </Switch>
    </div>
  )
}
