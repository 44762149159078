import React from 'react'
import styles from '../step.css'
import Container from 'orionsoft-parts/lib/components/Container'
import AutoForm from 'App/components/AutoForm'
import useQuery from 'apollo-hooks/lib/useApolloQuery'
import gql from 'graphql-tag'
import useRef from 'App/hooks/useRef'
import Button from 'orionsoft-parts/lib/components/Button'
import useRouter from 'App/hooks/useRouter'

export default function Start(props) {
  const button = useRef()
  const {history} = useRouter()
  const {me} = useQuery({
    query: gql`
      query getMe {
        me {
          _id
          profile {
            firstName
            lastName
            birthDate
            genre
            phone
            rut
          }
          paymentData {
            bankId
            bankAccountType
            bankAccountNumber
          }
        }
      }
    `
  })
  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.content}>
          <h2>Ingresa tus datos</h2>
          <br />
          <div>
            Necesitamos que completes el formulario con tus datos reales. Es requisito para que
            puedas participar del programa
          </div>
          <br />
          <div className={styles.tos}>
            El tratamiento de tus datos personales, será con la exclusiva finalidad de llevar a cabo
            el presente proceso de crédito hipotecario y no podrán ser utilizados para otros fines.
          </div>
          <br />
          <div>
            <AutoForm
              mutation="setUserProfile"
              omit="userId"
              doc={{userId: me._id, profile: me.profile, paymentData: me.paymentData}}
              buttonRef={button}
              onSuccess={() => history.push('/referidos-hipotecario-destacame/referir')}
            />
          </div>
          <br />
          <Button ref={button} primary>
            Siguiente
          </Button>
          <br />
          <br />
          <div className={styles.tos}>
            Para poder contactarte y mostrarte los productos que Banco Consorcio ofrece, es
            necesario completar el siguiente formulario con tus datos personales.
          </div>
        </div>
      </Container>
    </div>
  )
}
